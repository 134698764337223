<template>
  <div>
    <Navbar />
    <div class="exp-popup open">
      <div class="exp-popup__container">
        <div class="exp-align-center">
          <div class="exp-popup__icon">
            <Scream />
          </div>
          <div class="exp-popup__title exp-margin-top-30">
            Page inconnue
            <ExpBtn type="primary" to="landing">
              Retourner à l'accueil
            </ExpBtn>
          </div>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
  import ExpBtn from '../components/Button.vue';
  import Navbar from '../components/Navbar.vue';
  import Scream from '../assets/svg/scream.vue';

  export default {
    name: "NotFound",

    components: {
      ExpBtn,
      Navbar,
      Scream,
    },
  };
</script>